import "./main.css";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-copyright">&copy; CourtHub 2024</div>
            <div className="footer-info">Kontaktirajte nas za više informacija na Instagram stranici @courthub24</div>
        </div>
    );
};

export default Footer;
