import { useState } from "react";
import Modal from "../../common/Modal/Modal";
import ModalBody from "../../common/Modal/ModalBody";
import ModalFooter from "../../common/Modal/ModalFooter";
import Button from "../../common/Button/Button";
import SuccessMessage from "../../common/Modal/SuccessMessage";
import ErrorMessage from "../../common/Modal/ErrorMessage";
import "./main.css";
import { addPlayerToTournament } from "../../../api/tournaments";

const JoinTournamentConfirmationModal = ({ isOpen, onClose, onSubmit, userId, tournamentId, category }) => {
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async () => {
        const result = await addPlayerToTournament(tournamentId, userId, category);

        if (result.success) {
            setSuccess(true);
            setError(false);
        } else {
            setSuccess(false);
            setError(true);
            setErrorMessage(result.errorMessage);
        }
    };

    const handleModalClose = () => {
        onClose();
    };

    const handleSuccess = () => {
        handleModalClose();
        onSubmit();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleModalClose}>
            {!isSuccess && !isError ? (
                <>
                    <ModalBody>
                        <div className="join-tournament-confirmation-body-message">
                            Da li ste sigurni da želite da se prijavite na turnir?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="join-tournament-confirmation-body-buttons">
                            <Button onClick={handleModalClose}>Odustani</Button>
                            <Button primary onClick={handleSubmit}>
                                Prijavi me
                            </Button>
                        </div>
                    </ModalFooter>
                </>
            ) : null}
            <div>
                {isSuccess && !isError ? (
                    <SuccessMessage handleClose={handleSuccess} messageTitle={"Uspešna prijava!"} messageText={""} />
                ) : null}
                {isError && !isSuccess ? (
                    <ErrorMessage errorMessage={errorMessage} handleClose={handleModalClose} />
                ) : null}
            </div>
        </Modal>
    );
};

export default JoinTournamentConfirmationModal;
