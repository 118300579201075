import "./main.css";

const Button = (props) => {
    const { children, onClick, primary, className, ...rest } = props;

    const classesList = className + (primary ? " court-hub-button-primary" : " court-hub-button-default");

    return (
        <button className={classesList} type="button" onClick={onClick} {...rest}>
            {children}
        </button>
    );
};

export default Button;
