import { useEffect, useState } from "react";
import { auth, db, googleProvider, sendEmailVerification } from "../firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    signInWithPopup,
    sendPasswordResetEmail,
} from "firebase/auth";

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState("");

    useEffect(() => {
        const handleAuthChanged = async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);

                if (!currentUser.emailVerified) {
                    sessionStorage.setItem("court_hub_email_verified", "not_verified");
                }

                if (
                    sessionStorage.getItem("court_hub_email_verified") === "not_verified" &&
                    currentUser.emailVerified
                ) {
                    reloadUser();
                }
            } else {
                setUser(null);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, handleAuthChanged);
        return () => unsubscribe();
    }, []);

    const signIn = async (email, password) => {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            setUser(result.user);
            return { success: true, user: result.user };
        } catch (err) {
            const errorMessage = "Greška pri logovanju!";
            setError(errorMessage);
            return { success: false, error: errorMessage };
        }
    };

    const signInWithGoogle = async () => {
        try {
            // Step 1: Sign in with Google
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Step 2: Check if the user already exists in Firestore
            const userDoc = await getDoc(doc(db, "users", user.uid));

            if (!userDoc.exists()) {
                // If the user does not exist, add them to Firestore
                await setDoc(doc(db, "users", user.uid), {
                    firstName: user.displayName.split(" ")[0],
                    lastName: user.displayName.split(" ").slice(1).join(" "),
                    username: user.email.split("@")[0],
                    email: user.email,
                    createdAt: new Date(),
                });
            }

            setUser(user);
            return { success: true, user: user };
        } catch (error) {
            const errorMessage = "Greška pri logovanju!";
            setError(errorMessage);
            return { success: false, error: errorMessage };
        }
    };

    const signUp = async (firstName, lastName, email, password, phoneNumber) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await sendEmailVerification(user);

            await setDoc(doc(db, "users", user.uid), {
                firstName: firstName,
                lastName: lastName,
                username: email,
                email: email,
                phoneNumber: phoneNumber,
                createdAt: new Date(),
            });

            setUser(user);
            return { success: true, user: user };
        } catch (err) {
            const errorMessage = "Greška pri kreiranju naloga!";
            setError(errorMessage);
            return { success: false, error: errorMessage };
        }
    };

    const logOff = async () => {
        await signOut(auth);
    };

    const reloadUser = async () => {
        await auth?.currentUser?.getIdToken(true);
        await auth?.currentUser?.reload();
        sessionStorage.setItem("court_hub_email_verified", "verified");
    };

    const sendNewPasswordEmail = async (email) => {
        try {
            if (!email) {
                const errorMessage = "Uneti email nije validan!";
                setError(errorMessage);
                return { success: false, error: errorMessage };
            }
            await sendPasswordResetEmail(auth, email);
            return { success: true };
        } catch (err) {
            const errorMessage = "Greška pri resetovanju šifre!";
            setError(errorMessage);
            return { success: false, error: errorMessage };
        }
    };

    return {
        signIn,
        signInWithGoogle,
        signUp,
        logOff,
        user,
        error,
        sendNewPasswordEmail,
    };
};
