import { Modal as MuiModal, Box } from "@mui/material";
import "./main.css";

const Modal = ({ isOpen, onClose, children }) => {
    return (
        <MuiModal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="court-hub-modalContent">{children}</Box>
        </MuiModal>
    );
};

export default Modal;
