import { db } from "../firebaseConfig";
import { getDoc, doc, query, where, collection, getDocs } from "firebase/firestore";

export const fetchUserInfo = async (userId) => {
    const usersRef = doc(db, "users", userId);

    try {
        const snapshot = await getDoc(usersRef);
        if (snapshot.exists()) return snapshot.data();

        return null;
    } catch (error) {
        console.log(error);
    }
};

export const fetchUserInfoByEmail = async (emailAddress) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", emailAddress));

    try {
        const snapshot = await getDocs(q);
        const users = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        if (users && users.length > 0) {
            return users[0];
        }

        return null;
    } catch (error) {
        console.log(error);
    }
};
