import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PagedCourts from "./components/Courts/PagedCourts";
import Register from "./components/Auth/Register";
import { useAuth } from "./hooks/useAuth";
import MyCourts from "./components/MyCourts/MyCourts";
import Login from "./components/Auth/Login";
import Court from "./components/Court/Court";
import { MyProfile } from "./components/MyProfile/MyProfile";
// import MyLeagues from "./components/MyLeagues/MyLeagues";
import Tournaments from "./components/Tournaments/Tournaments";
import ResetPassword from "./components/Auth/ResetPasword";
import ResetPasswordEmailSent from "./components/Auth/ResetPasswordEmailSent";

const queryClient = new QueryClient();

function App() {
    const { signIn, signInWithGoogle, signUp, logOff, user, error, sendNewPasswordEmail } = useAuth();

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter basename="/">
                <div className="App">
                    <Routes>
                        <Route path="/" element={<PagedCourts user={user} signOut={logOff} />} />
                        <Route
                            path="/login"
                            element={
                                <Login
                                    signIn={signIn}
                                    googleSignIn={signInWithGoogle}
                                    signUp={signUp}
                                    user={user}
                                    error={error}
                                    sendNewPasswordEmail={sendNewPasswordEmail}
                                />
                            }
                        />
                        <Route
                            path="/register"
                            element={
                                <Register
                                    signIn={signIn}
                                    googleSignIn={signInWithGoogle}
                                    signUp={signUp}
                                    user={user}
                                    error={error}
                                />
                            }
                        />
                        <Route path="/courts/:courtId" element={<Court user={user} signOut={logOff} />} />
                        <Route path="/mycourts" element={<MyCourts user={user} signOut={logOff} />} />
                        <Route path="/myprofile" element={<MyProfile user={user} signOut={logOff} />} />
                        {/* <Route path="/myleagues" element={<MyLeagues user={user} signOut={logOff} />} /> */}
                        <Route path="/tournaments" element={<Tournaments user={user} signOut={logOff} />} />
                        <Route
                            path="/forgotpassword"
                            element={<ResetPassword error={error} sendNewPasswordEmail={sendNewPasswordEmail} />}
                        />
                        <Route path="/resetpasswordconfirmation" element={<ResetPasswordEmailSent />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
