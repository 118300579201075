import { db } from "../firebaseConfig";
import { collection, getDocs, query, doc, getDoc, runTransaction, where } from "firebase/firestore";

export const fetchTournaments = async () => {
    const tournamentsRef = collection(db, "tournaments");
    const q = query(tournamentsRef);

    try {
        const snapshot = await getDocs(q);
        const tournaments = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return tournaments;
    } catch (error) {
        console.log(error);
    }
};

const fetchTournamentById = async (tournamentId) => {
    const tournamentRef = doc(db, "tournaments", tournamentId);

    try {
        const snapshot = await getDoc(tournamentRef);
        if (snapshot.exists()) return snapshot.data();

        return null;
    } catch (error) {
        console.log(error);
    }
};

const isPlayerInTheTournament = async (tournamentId, playerId) => {
    const playersRef = doc(db, "tournament_players", tournamentId + "_" + playerId);

    try {
        const snapshot = await getDoc(playersRef);
        if (snapshot.exists()) return snapshot.data();

        return null;
    } catch (error) {
        console.log(error);
    }
};

export const addPlayerToTournament = async (tournamentId, playerId, category) => {
    try {
        if (!tournamentId || !playerId) {
            return { success: false, errorMessage: "Korisnik nije ulogovan!" };
        }

        const tournament = await fetchTournamentById(tournamentId);

        if (tournament && tournament.players_registered >= tournament.num_players) {
            return { success: false, errorMessage: "Sva mesta na turniru su popunjena!" };
        }

        const player = await isPlayerInTheTournament(tournamentId, playerId);

        if (player) {
            return { success: false, errorMessage: "Već ste prijavljeni na ovaj turnir!" };
        }

        await addPlayerToTournamentInTransaction(tournamentId, playerId, category);

        return { success: true };
    } catch (e) {
        console.error(e);
        return { success: false, errorMessage: "Serverska greška!" };
    }
};

const addPlayerToTournamentInTransaction = async (tournamentId, playerId, category) => {
    const tournamentRef = doc(db, "tournaments", tournamentId);
    const tournamentPlayerRef = doc(db, "tournament_players", tournamentId + "_" + playerId);
    const tournamentPlayersCountRef = collection(db, "tournament_players");

    try {
        await runTransaction(db, async (transaction) => {
            const tournamentDoc = await transaction.get(tournamentRef);

            if (!tournamentDoc.exists()) {
                throw new Error("Tournament does not exist!");
            }

            const tournament = tournamentDoc.data();

            const queryParams = [where("tournament_id", "==", tournamentId)];

            let q = query(tournamentPlayersCountRef, ...queryParams);
            const snapshot = await getDocs(q);

            const currentTournamentPlayers = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            if (currentTournamentPlayers.length >= tournament.num_players) {
                throw new Error("Tournament already full!");
            }

            transaction.set(tournamentPlayerRef, {
                tournament_id: tournamentId,
                player_id: playerId,
                category16: category.cat16,
                category45: category.cat45,
            });
        });
    } catch (error) {
        throw error;
    }
};
