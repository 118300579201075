import Modal from "../../common/Modal/Modal";
import ModalBody from "../../common/Modal/ModalBody";
import ModalFooter from "../../common/Modal/ModalFooter";
import Button from "../../common/Button/Button";
import "./main.css";

const JoinTournamentTermsAndConditionsModal = ({ isOpen, onClose }) => {
    const handleModalClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleModalClose}>
            <>
                <ModalBody>
                    <div className="join-tournament-terms-body-message">
                        <p className="join-tournament-terms-body-message-title">
                            Saglasnost na uslove učešća na turniru
                        </p>
                        <p>
                            Pre nego što se prijavite za učešće na našem teniskom turniru, molimo Vas da pažljivo
                            pročitate sledeće uslove i potvrdite svoju saglasnost.
                        </p>
                        <p>
                            Učešće u teniskim takmičenjima može predstavljati fizički napor i nosi određene rizike od
                            raznih fizičkih povreda (istegnuća, povrede zglobova i mišića i dr.), kao i ozbiljnih
                            zdravstvenih problema poput srčanog udara.
                        </p>
                        <p>
                            U skladu sa tim, svi takmičari su saglasni da su svesni ovih rizika i da se takmiče na
                            sopstvenu odgovornost. Organizatori turnira ne zahtevaju obavezne lekarske preglede, ali
                            preporučujemo da se konsultujete sa lekarom pre nego što se odlučite za učešće, posebno ako
                            imate bilo kakve zdravstvene probleme ili ste u grupi sa povećanim rizikom. Organizatori
                            turnira ne snose odgovornost za bilo kakve povrede ili zdravstvene probleme koji mogu
                            nastati tokom takmičenja ili usled njega.
                        </p>
                        <p>
                            Potvrđujete da ste saglasni sa uslovima učešća i da ste fizički sposobni za takmičenje.
                            Molimo vas da označite polje kao potvrdu da ste pročitali uslove i da ste saglasni sa njima.
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="join-tournament-terms-body-buttons">
                        <Button onClick={handleModalClose}>Zatvori</Button>
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
};

export default JoinTournamentTermsAndConditionsModal;
