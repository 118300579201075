import { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./main.css";
import TournamentCard from "./TournamentCard/TournamentCard";
import { fetchTournaments } from "../../api/tournaments";
import Footer from "../Footer/Footer";

const validMonths = [
    { id: "MAY2025", desc: "Maj" },
    { id: "JUN2025", desc: "Jun" },
    { id: "JUL2025", desc: "Jul" },
    { id: "AUG2025", desc: "Avgust" },
    { id: "SEP2025", desc: "Septembar" },
    { id: "OCT2025", desc: "Oktobar" },
];

const Tournaments = ({ user, signOut }) => {
    const [tournaments, setTournaments] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(validMonths[0]);
    const [isLoading, setIsLoading] = useState(false);

    const isLoggedIn = user?.email && user?.emailVerified;

    useEffect(() => {
        const fetchAllTournaments = async () => {
            setIsLoading(true);
            const allTournaments = await fetchTournaments();
            setTournaments(allTournaments);
            setIsLoading(false);
        };

        fetchAllTournaments();
    }, []);

    const handleNextMonth = () => {
        const selectedMonthIdx = validMonths.findIndex((t) => t.id === selectedMonth.id);

        if (selectedMonthIdx === -1) {
            setSelectedMonth(validMonths[0]);
            return;
        }

        const nextMonthIdx = selectedMonthIdx + 1;

        if (nextMonthIdx < 0 || nextMonthIdx >= validMonths.length) {
            return;
        }

        setSelectedMonth(validMonths[nextMonthIdx]);
    };

    const handlePrevMonth = () => {
        const selectedMonthIdx = validMonths.findIndex((t) => t.id === selectedMonth.id);

        if (selectedMonthIdx === -1) {
            setSelectedMonth(validMonths[0]);
            return;
        }

        const prevMonthIdx = selectedMonthIdx - 1;

        if (prevMonthIdx < 0 || prevMonthIdx >= validMonths.length) {
            return;
        }

        setSelectedMonth(validMonths[prevMonthIdx]);
    };

    const tournamentsForMonth = tournaments
        .sort((a, b) => parseInt(a.tournament_id) - parseInt(b.tournament_id))
        .filter((t) => t.month === selectedMonth.id);

    return (
        <div className="tournaments-box">
            <div className="tournaments-contentContainer">
                <div className="tournaments-heroContainer">
                    <div className="tournaments-topSection">
                        <Navbar user={user} signOut={signOut} />
                        <div className="tournaments-heroSectionTitle">
                            <div>Započni svoju</div>
                            <div>tenisku avanturu!</div>
                        </div>
                    </div>
                </div>
                <div className="tournaments-selectionBox"></div>
                <div className="tournaments-contentBox">
                    <div className="tournaments-contentBox-calendar">
                        <div className="tournaments-contentBox-calendar-month">
                            {selectedMonth?.id !== validMonths[0].id && (
                                <button
                                    className="tournaments-contentBox-calendar-month-arrowButton"
                                    onClick={handlePrevMonth}
                                >
                                    &lt;
                                </button>
                            )}
                            <div className="tournaments-contentBox-calendar-month-name">{selectedMonth?.desc}</div>
                            {selectedMonth?.id !== validMonths[validMonths.length - 1].id && (
                                <button
                                    className="tournaments-contentBox-calendar-month-arrowButton"
                                    onClick={handleNextMonth}
                                >
                                    &gt;
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="tournaments-contentBox-notification">Prijave za turnire počinju 01.04.2025.</div>
                    {tournamentsForMonth && tournamentsForMonth.length > 0 ? (
                        <div className="tournaments-contentBox-tournamentList">
                            {tournamentsForMonth.map((t) => (
                                <TournamentCard key={t.id} tournament={t} isLoggedIn={isLoggedIn} userId={user?.uid} />
                            ))}
                        </div>
                    ) : (
                        <div className="tournaments-contentBox-noTournaments">
                            {isLoading ? "Učitavam turnire..." : "Nema turnira u ovom mesecu"}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Tournaments;
