import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessMessage = ({ handleClose, messageTitle = "Uspešno ste poslali zahtev.", messageText }) => {
    return (
        <div>
            <div style={styles.successIcon}>
                <CheckCircleIcon />
            </div>
            <div style={styles.successTitle}>{messageTitle}</div>
            <div style={styles.successSubtitle}>{messageText}</div>
            <div style={styles.successButtonContainer}>
                <button style={styles.successButton} onClick={handleClose}>
                    OK
                </button>
            </div>
        </div>
    );
};

const styles = {
    successIcon: {
        color: "green",
        fontSize: "70px",
        display: "flex",
        justifyContent: "center",
    },
    successTitle: {
        fontSize: "17px",
        fontWeight: "700",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
    },
    successSubtitle: {
        fontSize: "11px",
        fontWeight: "700",
        display: "flex",
        justifyContent: "center",
        marginTop: "5px",
        color: "gray",
    },
    successButtonContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
    },
    successButton: {
        padding: "8px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: "#007BFF",
        color: "#fff",
        textTransform: "uppercase",
        fontWeight: "700",
        width: "50%",
    },
};

export default SuccessMessage;
