import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import {
    getAuth,
    setPersistence,
    browserLocalPersistence,
    GoogleAuthProvider,
    sendEmailVerification,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const production = process.env.REACT_APP_ENV !== "Development";
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

const firebaseConfig = production
    ? {
          apiKey: "AIzaSyCuesDhvmYwU46GU8zDEFTdRCnZBGC9Nwc",
          authDomain: "court-hub.firebaseapp.com",
          projectId: "court-hub",
          storageBucket: "court-hub.appspot.com",
          messagingSenderId: "1029505153885",
          appId: "1:1029505153885:web:7f817c3a3313041fd686b4",
          measurementId: "G-MM4CDPDN49",
      }
    : {
          apiKey: "AIzaSyDBw7EuFfipmmYyXi9_ZTM1fZBtGiabNec",
          authDomain: "court-hub-dev.firebaseapp.com",
          projectId: "court-hub-dev",
          storageBucket: "court-hub-dev.appspot.com",
          messagingSenderId: "314264475496",
          appId: "1:314264475496:web:3acad7a5ea69f3265668dd",
      };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(recaptchaKey),
    isTokenAutoRefreshEnabled: true,
});

const googleProvider = new GoogleAuthProvider();
setPersistence(auth, browserLocalPersistence);

export { auth, db, storage, googleProvider, sendEmailVerification, appCheck };
