import MyCourtsBanner from "../MyCourts/MyCourtsBanner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
import "./main.css";
import { useState } from "react";

const Navbar = ({ user, signOut, darkLogo = false, border = false }) => {
    const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

    const navigate = useNavigate();
    const handleLogoClick = () => navigate("/");
    const handleProfileClick = () => navigate("/myprofile");
    // const handleMyLeaguesClick = () => navigate("/myleagues");
    const handleTournamentsClick = () => navigate("/tournaments");

    return (
        <>
            <div className="navbar">
                <div className={darkLogo ? "darklogo" : "logo"} onClick={handleLogoClick}>
                    CourtHub
                </div>
                <>
                    <div className="loggedInUserNavbar">
                        <div
                            className="navbar-menu-item"
                            style={{
                                color: darkLogo ? "black" : "white",
                                fontWeight: darkLogo ? "bold" : "normal",
                            }}
                            onClick={handleTournamentsClick}
                        >
                            Turniri
                        </div>
                        {user && <MyCourtsBanner user={user} darkMode={darkLogo} />}
                        {/* {user && (
                                <div
                                    className="navbar-menu-item"
                                    style={{
                                        color: darkLogo ? "black" : "white",
                                        fontWeight: darkLogo ? "bold" : "normal",
                                    }}
                                    onClick={handleMyLeaguesClick}
                                >
                                    Moje Lige
                                </div>
                            )} */}
                        {user && (
                            <div
                                className="navbar-menu-item"
                                style={{
                                    color: darkLogo ? "black" : "white",
                                    fontWeight: darkLogo ? "bold" : "normal",
                                }}
                                onClick={handleProfileClick}
                            >
                                Moj Profil
                            </div>
                        )}
                        {user && (
                            <div className="logoutContainer">
                                <button className="registerButton" onClick={signOut}>
                                    Izlogujte se
                                </button>
                            </div>
                        )}
                        {!user && (
                            <>
                                <button className="loginButton">
                                    <Link className="loginLink" to="/login">
                                        Ulogujte se
                                    </Link>
                                </button>
                            </>
                        )}
                    </div>
                    <div className="navbar-hamburgerMenu">
                        <div className="navbar-hamburgerMenu-icon">
                            <Hamburger
                                color={darkLogo ? "black" : "white"}
                                toggled={isHamburgerMenuOpen}
                                toggle={setHamburgerMenuOpen}
                            />
                        </div>
                        {isHamburgerMenuOpen && (
                            <div className="navbar-hamburgerMenu-options">
                                {user && <MyCourtsBanner user={user} darkMode={true} />}
                                <div
                                    style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleTournamentsClick}
                                >
                                    Turniri
                                </div>
                                {user && (
                                    <div
                                        style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleProfileClick}
                                    >
                                        Moj Profil
                                    </div>
                                )}
                                {/* {user && (
                                        <div
                                            style={{
                                                color: "black",
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                            }}
                                            onClick={handleMyLeaguesClick}
                                        >
                                            Moje Lige
                                        </div>
                                    )} */}
                                {user && (
                                    <div>
                                        <button className="registerButton" onClick={signOut}>
                                            Izlogujte se
                                        </button>
                                    </div>
                                )}
                                {!user && (
                                    <>
                                        <button className="registerButton" onClick={signOut}>
                                            <Link className="loginLink" to="/login">
                                                Ulogujte se
                                            </Link>
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </>
            </div>
            {border ? <div className="navbarBorder"></div> : null}
        </>
    );
};

export default Navbar;
