import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./main.css";

const ResetPassword = ({ error, sendNewPasswordEmail }) => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleForgotPassword = async () => {
        const result = await sendNewPasswordEmail(email);

        if (result && result.success) {
            navigate("/resetpasswordconfirmation");
        }
    };

    return (
        <div className="mainContainer">
            <div className="logoImage"></div>
            <div className="registerSection">
                <div className="registerFormSection">
                    <div className="registrationHeader">
                        <div className="auth-header">
                            <h2>Promeni šifru</h2>
                        </div>
                        <div className="headerInfo">
                            <span>Unesi email adresu za svoj Court Hub nalog</span>
                        </div>
                    </div>

                    <form className="registerForm">
                        <div className="formGroup">
                            <label className="formLabel">EMAIL</label>
                            <input
                                className="formTextInput"
                                type="text"
                                placeholder="tvoja email adresa"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="formGroup">
                            <button type="button" className="btnPrimary" onClick={handleForgotPassword}>
                                PROMENI ŠIFRU
                            </button>
                        </div>

                        {error && <p className="errorMessage">{error}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
