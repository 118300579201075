import Button from "../../common/Button/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./main.css";
import JoinTournamentModal from "./JoinTournamentModal";
import { useState } from "react";

const TournamentCard = ({ tournament, isLoggedIn, userId }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openJoinModal = () => {
        setIsOpen(true);
    };

    const getATPPointsClass = (points) => {
        switch (points) {
            case 1000:
                return "tournament-card-points-1000";
            case 500:
                return "tournament-card-points-500";
            case 250:
                return "tournament-card-points-250";
            default:
                return "";
        }
    };

    return (
        <div className="tournament-card-box">
            <div className="tournament-card">
                <div className="tournament-card-background"></div>
                <div className={`tournament-card-points ${getATPPointsClass(tournament?.points)}`}></div>
                <div className="tournament-card-info">
                    <div className="tournament-card-info-box">
                        <div className="tournament-card-info-courtName">{tournament?.court_name}</div>
                        <div className="tournament-card-info-address">{tournament?.court_address}</div>
                        <div className="tournament-card-info-city">{tournament?.court_city}</div>
                    </div>
                </div>
                <div className="tournament-card-dates">
                    <div className="tournament-card-dates-combined">
                        {tournament?.month_desc?.split(",")[1]} {tournament?.month_desc?.split(",")[0]}
                    </div>
                    <div className="tournament-card-dates-days">{tournament?.month_desc?.split(",")[1]}</div>
                    <div className="tournament-card-dates-month">{tournament?.month_desc?.split(",")[0]}</div>
                </div>
                <div className="tournament-card-players">
                    <div className="tournament-card-players-top">
                        <div className="tournament-card-players-top-box">
                            <div className="tournament-card-players-top-total">
                                <div className="tournament-card-players-top-total-icon">
                                    <AccountCircleIcon className="tournament-card-players-top-total-icon-svg" />
                                </div>
                                <div className="tournament-card-players-top-total-num">:{tournament?.num_players}</div>
                            </div>
                            <div className="tournament-card-players-top-btn">
                                <Button primary onClick={openJoinModal}>
                                    Prijavi se!
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="tournament-card-players-bottom">
                        <div className="tournament-card-players-bottom-box">
                            Ukupno prijavljeno: {tournament?.players_registered}
                        </div>
                    </div>
                </div>
            </div>
            <div className="tournament-card-border"></div>
            <JoinTournamentModal
                isOpen={isOpen}
                isLoggedIn={isLoggedIn}
                tournamentId={tournament.id}
                userId={userId}
                onClose={() => setIsOpen(false)}
            />
        </div>
    );
};

export default TournamentCard;
