import React from "react";
import "./main.css";

const ResetPasswordEmailSent = () => {
    return (
        <div className="mainContainer">
            <div className="logoImage"></div>
            <div className="registerSection">
                <div className="registerFormSection">
                    <div className="registrationHeader">
                        <div className="auth-header">
                            <h2>Zahtev za promenu šifre je poslat!</h2>
                        </div>
                        <div className="headerInfo">
                            <span>Proverite svoje email sanduče kako biste promenili šifru.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordEmailSent;
