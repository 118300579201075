import { useState, useEffect, useCallback } from "react";
import Navbar from "../Navbar/Navbar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./main.css";
import { fetchUserInfo } from "../../api/fetchUserInfo";
import { fetchBookingsByUser } from "../../api/fetchBookings";
import { formatDate, formatTime } from "../../utils/datesUtils";
import { useBooking } from "../../hooks/useBooking";
import myProfileBackground from "../../images/my_profile.jpeg";
import { onBookingsChange } from "../../api/onBookingsChange";
import ConfirmationModal from "../common/ConfirmationModal/ConfirmationModal";
import Footer from "../Footer/Footer";

export const MyProfile = ({ user, signOut }) => {
    const [userInfo, setUserInfo] = useState({});
    const [userBookings, setUserBookings] = useState([]);
    const [isCancelModalOpen, setCancelModalOpen] = useState(false);
    const [bookingIdToCancel, setBookingIdToCancel] = useState(null);
    const { cancelBooking } = useBooking({ user });

    useEffect(() => {
        const fetchUserInformation = async () => {
            if (user) {
                const result = await fetchUserInfo(user.uid);
                setUserInfo(result);
            }
        };

        fetchUserInformation();
    }, [user]);

    const fetchUserBookingsInfo = useCallback(async () => {
        if (user) {
            const result = await fetchBookingsByUser(user.email);
            setUserBookings(result);
        }
    }, [user]);

    useEffect(() => {
        fetchUserBookingsInfo();
    }, [user, fetchUserBookingsInfo]);

    const handleBookingsChanged = useCallback(() => {
        fetchUserBookingsInfo();
    }, [fetchUserBookingsInfo]);

    useEffect(() => {
        const cleanup = onBookingsChange(handleBookingsChanged);

        return () => cleanup();
    }, [handleBookingsChanged]);

    const pastBookings = userBookings?.filter(
        (t) =>
            new Date(t.created_at).getMonth() <= new Date().getMonth() &&
            new Date(t.created_at).getDate() < new Date().getDate()
    );
    const currentBookings = userBookings?.filter(
        (t) =>
            new Date(t.created_at).getMonth() >= new Date().getMonth() &&
            new Date(t.created_at).getDate() >= new Date().getDate()
    );

    const openCancelModal = (e) => {
        const bookingId = e.target.getAttribute("data-bookingid");
        setBookingIdToCancel(bookingId);
        setCancelModalOpen(true);
    };

    const closeCancelModal = () => {
        setCancelModalOpen(false);
        setBookingIdToCancel(null);
    };

    const handleCancelBooking = async (e) => {
        await cancelBooking(bookingIdToCancel);
        closeCancelModal();
    };

    const getCancelButtonText = (reservationStatus) => {
        switch (reservationStatus) {
            case "requested":
                return "Otkaži";
            case "accepted":
                return "Otkaži";
            case "rejected":
                return "Otkazan";
            case "cancelled":
                return "Otkazan";
            default:
                return "Otkaži";
        }
    };

    const isCancelButtonDisabled = (reservationStatus) => {
        switch (reservationStatus) {
            case "requested":
                return false;
            case "accepted":
                return false;
            case "rejected":
                return true;
            case "cancelled":
                return true;
            default:
                return true;
        }
    };

    return (
        <div>
            <div className="myprofile-contentContainer">
                <Navbar user={user} signOut={signOut} darkLogo={true} border={true} />
                <div className="myprofile-playerInfoBox">
                    <div className="myprofile-playerInfoBox-playerImage">
                        <AccountCircleIcon className="myprofile-playerInfoBox-playerImage-icon" />
                    </div>
                    <div className="myprofile-playerInfoBox-logobox">
                        <img className="myprofile-img" src={myProfileBackground} alt="myprofile_img" />
                        <div className="myprofile-playerInfoBox-name">{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
                    </div>
                    <div className="myprofile-playerInfoBox-matches">
                        <div className="myprofile-playerInfoBox-matches-count">{pastBookings?.length ?? 0}</div>
                        <div className="myprofile-playerInfoBox-matches-title">Odigranih mečeva</div>
                    </div>
                </div>
                <div className="myProfile-bookingsBox">
                    <div className="myProfile-bookings-title">Aktuelni termini</div>
                    <div className="myProfile-bookingsList">
                        {currentBookings.map((t) => (
                            <div key={t.id} className="myProfile-bookings-booking">
                                <div className="myProfile-bookings-bookingDate">{formatDate(new Date(t.date))}</div>
                                <div className="myProfile-bookings-bookingInfo">
                                    <div className="myProfile-bookings-bookingInfo-time">
                                        {formatTime(t.start_hour, t.start_minute, t.end_hour, t.end_minute)}
                                    </div>
                                    <div className="myProfile-bookings-bookingInfo-user">
                                        {t.reservation_requested_by}
                                    </div>
                                    <div className="myProfile-bookings-bookingInfo-button">
                                        <button
                                            data-bookingid={t.id}
                                            onClick={openCancelModal}
                                            disabled={isCancelButtonDisabled(t.reservation_status)}
                                        >
                                            {getCancelButtonText(t.reservation_status)}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="myProfile-bookingsBox">
                    <div className="myProfile-bookings-title">Prošli termini</div>
                    <div className="myProfile-bookingsList">
                        {pastBookings.map((t) => (
                            <div key={t.id} className="myProfile-bookings-booking">
                                <div className="myProfile-bookings-bookingDate">{formatDate(new Date(t.date))}</div>
                                <div className="myProfile-bookings-bookingInfo">
                                    <div className="myProfile-bookings-bookingInfo-time">
                                        {formatTime(t.start_hour, t.start_minute, t.end_hour, t.end_minute)}
                                    </div>
                                    <div className="myProfile-bookings-bookingInfo-user">
                                        {t.reservation_requested_by}
                                    </div>
                                    <div className="myProfile-bookings-bookingInfo-button">
                                        <button disabled>{getCancelButtonText(t.reservation_status)}</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ConfirmationModal
                isOpen={isCancelModalOpen}
                onClose={closeCancelModal}
                onSubmit={handleCancelBooking}
                message={"Da li ste sigurni da želite da otkažete ovaj termin?"}
            />
            <Footer />
        </div>
    );
};
