import React, { useState, useEffect, useCallback } from "react";
import { Modal, Box, MenuItem, Select } from "@mui/material";
import SuccessMessage from "../common/Modal/SuccessMessage";
import ErrorMessage from "../common/Modal/ErrorMessage";
import moment from "moment/moment";
import { bookingSuccessMessage, adminBookingSuccessMessage } from "../../utils/constants";
import "./main.css";

const defaultDaysSelection = [
    {
        day: "Mon",
        selected: false,
    },
    {
        day: "Tue",
        selected: false,
    },
    {
        day: "Wed",
        selected: false,
    },
    {
        day: "Thu",
        selected: false,
    },
    {
        day: "Fri",
        selected: false,
    },
    {
        day: "Sat",
        selected: false,
    },
    {
        day: "Sun",
        selected: false,
    },
];

const BookingModal = ({ isOpen, onClose, onSubmit, adminView = false, modalText = null }) => {
    const [date, setDate] = useState(new Date());
    const [startTime, setStartTime] = useState({ hour: 0, minute: 0 });
    const [endTime, setEndTime] = useState({ hour: 0, minute: 0 });
    const [user, setUser] = useState("");
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showRecurringOptions, setShowRecurringOptions] = useState(false);
    const [selectedDays, setSelectedDays] = useState(defaultDaysSelection);

    const minuteArray = [0, 30];
    const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const formatDate = (date) => {
        return date.toLocaleDateString("sr-Latn-RS", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric",
        });
    };

    const handleTimeChange = (event, setter) => {
        const { name, value } = event.target;
        setter((prevTime) => ({
            ...prevTime,
            [name]: parseInt(value, 10),
        }));
    };

    const handlePreviousDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        setDate(newDate);
    };

    const handleNextDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
    };

    const handleClose = useCallback(() => {
        setSuccess(false);
        setError(false);
        setErrorMessage("");
        setUser("");
        setShowRecurringOptions(false);
        setSelectedDays(defaultDaysSelection);
        onClose();
    }, [onClose]);

    const handleSubmit = async () => {
        const result = await onSubmit({
            date: moment(date).format("YYYY-MM-DD"),
            startTime,
            endTime,
            customUser: user,
            autoAccept: adminView,
            recurringDays: selectedDays.filter((t) => t.selected === true),
        });

        if (result && result?.success === true) {
            setSuccess(true);
            setError(false);
            setErrorMessage("");
            setUser("");
            setShowRecurringOptions(false);
            setSelectedDays(defaultDaysSelection);
        }

        if (result && result?.success === false) {
            setSuccess(false);
            setError(true);
            setErrorMessage(result?.errorMessage);
            setUser("");
            setShowRecurringOptions(false);
            setSelectedDays(defaultDaysSelection);
        }
    };

    const handleUserChange = (e) => {
        setUser(e.target.value);
    };

    useEffect(() => {
        if (!isOpen) {
            handleClose();
        }
    }, [isOpen, handleClose]);

    const handleDayButtonClicked = (e) => {
        const day = e.target.value;

        const newDays = selectedDays.map((d) => {
            if (d.day === day) {
                return {
                    day: day,
                    selected: !d.selected,
                };
            }
            return d;
        });

        setSelectedDays(newDays);
    };

    const getButtonColor = (value) => {
        const day = selectedDays.find((t) => t.day === value);
        if (day && day.selected) return "lightgreen";

        return "lightgray";
    };

    const dayMap = {
        Mon: "Pon",
        Tue: "Uto",
        Wed: "Sre",
        Thu: "Čet",
        Fri: "Pet",
        Sat: "Sub",
        Sun: "Ned",
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={"bookingModal-modalContent"}>
                {!isError && !isSuccess ? (
                    <>
                        {modalText && <div className="bookingModal-text">{modalText}</div>}
                        <div className="bookingModal-datePicker">
                            <div className="bookingModal-header">
                                <button className="bookingModal-arrowButton" onClick={handlePreviousDay}>
                                    &lt;
                                </button>
                                <div className="bookingModal-dateRange">{formatDate(date)}</div>
                                <button className="bookingModal-arrowButton" onClick={handleNextDay}>
                                    &gt;
                                </button>
                            </div>
                        </div>
                        <div className="bookingModal-timeBox">
                            <div className="bookingModal-timeSlot">
                                <Select
                                    className="bookingModal-timeSelect"
                                    value={startTime.hour}
                                    onChange={(e) => handleTimeChange(e, setStartTime)}
                                    name="hour"
                                    sx={{
                                        border: "none",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    {Array.from({ length: 24 }, (_, i) => (
                                        <MenuItem key={i} value={i}>
                                            {i.toString().padStart(2, "0")}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="bookingModal-timeSlot">
                                <Select
                                    className="bookingModal-timeSelect"
                                    value={startTime.minute}
                                    onChange={(e) => handleTimeChange(e, setStartTime)}
                                    name="minute"
                                    sx={{
                                        border: "none",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    {minuteArray.map((i) => (
                                        <MenuItem key={i} value={i}>
                                            {i.toString().padStart(2, "0")}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="bookingModal-separator">-</div>
                            <div className="bookingModal-timeSlot">
                                <Select
                                    className="bookingModal-timeSelect"
                                    value={endTime.hour}
                                    onChange={(e) => handleTimeChange(e, setEndTime)}
                                    name="hour"
                                    sx={{
                                        border: "none",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    {Array.from({ length: 24 }, (_, i) => (
                                        <MenuItem key={i} value={i}>
                                            {i.toString().padStart(2, "0")}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="bookingModal-timeSlot">
                                {" "}
                                <Select
                                    className="bookingModal-timeSelect"
                                    value={endTime.minute}
                                    onChange={(e) => handleTimeChange(e, setEndTime)}
                                    name="minute"
                                    sx={{
                                        border: "none",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    {minuteArray.map((i) => (
                                        <MenuItem key={i} value={i}>
                                            {i.toString().padStart(2, "0")}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        {adminView ? (
                            <div>
                                <div className="bookingModal-userBox">
                                    <label className="bookingModal-userLabel">Unesite ime ili email korisnika</label>
                                    <input
                                        className="bookingModal-userInput"
                                        type="text"
                                        value={user}
                                        onChange={handleUserChange}
                                    ></input>
                                </div>
                                <div className="bookingModal-recurringSection">
                                    <div className="bookingModal-recurringSection-input">
                                        <input
                                            type="checkbox"
                                            value={showRecurringOptions}
                                            onChange={() => setShowRecurringOptions((prev) => !prev)}
                                        />
                                        <label>Termin se ponavlja?</label>
                                    </div>
                                    {showRecurringOptions && (
                                        <div className="bookingModal-recurringOptions">
                                            <label>Ponavljaj ovaj termin svakog</label>
                                            <div className="bookingModal-recurringOptions-days">
                                                {weekDays.map((wd) => (
                                                    <button
                                                        key={wd}
                                                        className="bookingModal-recurringSection-day"
                                                        onClick={handleDayButtonClicked}
                                                        value={wd}
                                                        style={{ backgroundColor: getButtonColor(wd) }}
                                                    >
                                                        {dayMap[wd]}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null}
                        <div className="bookingModal-buttons">
                            <button className="bookingModal-cancel" onClick={onClose}>
                                Odustani
                            </button>
                            <button className="bookingModal-confirm" onClick={handleSubmit}>
                                Pošalji zahtev
                            </button>
                        </div>
                    </>
                ) : null}
                <div className="bookingModal-message">
                    {isSuccess && !isError ? (
                        <SuccessMessage
                            handleClose={handleClose}
                            messageText={adminView ? adminBookingSuccessMessage : bookingSuccessMessage}
                        />
                    ) : null}
                    {isError && !isSuccess ? (
                        <ErrorMessage errorMessage={errorMessage} handleClose={handleClose} />
                    ) : null}
                </div>
            </Box>
        </Modal>
    );
};

export default BookingModal;
