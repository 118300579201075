import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../common/Modal/Modal";
import ModalBody from "../../common/Modal/ModalBody";
import Button from "../../common/Button/Button";
import "./main.css";
import JoinTournamentConfirmationModal from "./JoinTournamentConfirmationModal";
import JoinTournamentTermsAndConditionsModal from "./JoinTournamentTermsAndConditionsModal";

const JoinTournamentModal = ({ isOpen, onClose, onSubmit, isSuccess, isError, isLoggedIn, userId, tournamentId }) => {
    const [category16, setCategory16] = useState(false);
    const [category45, setCategory45] = useState(false);
    const [terms, setTerms] = useState(false);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [isTermsAndConditionsModalOpen, setTermsAndConditionsModalOpen] = useState(false);

    const shouldDisableJoin = (!category16 && !category45) || !terms;

    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate("/login");
    };

    const joinTournamentConfirmation = () => {
        setConfirmationOpen(true);
    };

    const handleModalClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleModalClose}>
            {!isSuccess && !isError ? (
                <>
                    <ModalBody>
                        {!isLoggedIn ? (
                            <div className="join-tournament-modal-not-logged-in">
                                <div className="join-tournament-modal-not-logged-in-message">
                                    <div>Hvala Vam na interesovanju za igranje turnira preko platforme Court Hub.</div>
                                    <div>
                                        Da biste mogli da se prijavite na turnir, potrebno je da imate nalog na našoj
                                        platformi i da vaš email bude verifikovan. Kliknite dugme ispod da biste
                                        napravili nalog ili se prijavili već postojećim nalogom.
                                    </div>
                                </div>
                                <div className="join-tournament-modal-not-logged-in-redirect">
                                    <Button primary onClick={navigateToLogin}>
                                        Prijavi se
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                        {isLoggedIn ? (
                            <div className="join-tournament-modal-logged-in">
                                <div className="join-tournament-modal-logged-in-message">
                                    Izaberite kategoriju u kojoj želite da se takmičite:
                                </div>
                                <div className="join-tournament-modal-logged-in-options">
                                    <div className="join-tournament-modal-logged-in-options-opt">
                                        <label>16+</label>
                                        <input
                                            type="checkbox"
                                            checked={category16}
                                            onChange={() => setCategory16((prev) => !prev)}
                                        />
                                    </div>
                                    <div className="join-tournament-modal-logged-in-options-opt">
                                        <label>45+</label>
                                        <input
                                            type="checkbox"
                                            checked={category45}
                                            onChange={() => setCategory45((prev) => !prev)}
                                        />
                                    </div>
                                </div>
                                <div className="join-tournament-modal-logged-in-terms">
                                    <input type="checkbox" checked={terms} onChange={() => setTerms((prev) => !prev)} />
                                    <label
                                        className="join-tournament-modal-logged-in-terms-label"
                                        onClick={() => setTermsAndConditionsModalOpen(true)}
                                    >
                                        Potvrdjujem da sam pročitao/la uslove učešća i saglasan/a sam sa njima
                                    </label>
                                </div>
                                <div className="join-tournament-modal-logged-in-buttons">
                                    <Button primary onClick={joinTournamentConfirmation} disabled={shouldDisableJoin}>
                                        Prijavi me na turnir!
                                    </Button>
                                    <Button onClick={handleModalClose}>Odustani</Button>
                                </div>
                                <JoinTournamentConfirmationModal
                                    isOpen={isConfirmationOpen}
                                    onClose={() => setConfirmationOpen(false)}
                                    onSubmit={handleModalClose}
                                    userId={userId}
                                    tournamentId={tournamentId}
                                    category={{ cat16: category16, cat45: category45 }}
                                />
                                <JoinTournamentTermsAndConditionsModal
                                    isOpen={isTermsAndConditionsModalOpen}
                                    onClose={() => setTermsAndConditionsModalOpen(false)}
                                />
                            </div>
                        ) : null}
                    </ModalBody>
                </>
            ) : null}
        </Modal>
    );
};

export default JoinTournamentModal;
